import { BrowserRouter as Router, Routes, Route, useRoutes} from "react-router-dom";

import "./config/Firebase.jsx"

import "@fontsource/montserrat"

import Home from "./pages/Home.jsx"




const App = () => {
  let routes = useRoutes([
    { path: "/", element: <Home /> },
    
  ]);
  return routes;
};

const AppWrapper = () => {
  return (
    <Router>
      <App />
    </Router>
  );
};

export default AppWrapper;
