import React, { Fragment, useState } from "react";

import { Row, Col, Image } from "react-bootstrap";

import Popup from "reactjs-popup";
import "./Home.css";
import Carousel from "react-bootstrap/Carousel";
import "reactjs-popup/dist/index.css";
const Home = () => {
  // const reservMadrid = "mailto:dani.madrid@fourseasons.com";
  const reservTarifa =
    "https://www.covermanager.com/reserve/module_restaurant/bibo-tarifa/spanish/2024-05-09?timefix=18:30&only_dinner=1";
  const reservFinca =
    "https://www.covermanager.com/reserve/module_restaurant/restaurante-lobitodemar-lafinca/spanish/2024-04-19?timefix=19:00&only_dinner=1";
  let [isOpen, setIsOpen] = useState(false);
  let [idframe, setIdFrame] = useState("1");
  return (
    <>
      <div
        className="app"
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Row style={{ padding: "0", margin: "0", width: "100%" }}>
          <Image
            src="/assets/principalimagpc.png"
            style={{ width: "100%", padding: "0px" }}
          ></Image>
        </Row>

        <div className="main">
          <Row
            style={{
              padding: "0",
              // minHeight: "500px",
              margin: "15px 0px  ",
              width: "100%",
              justifyContent: "space-around",
              gap: "25px",
            }}
          >
            <Col xs={10} md={5}>
              <div className="cardronq">
                <div style={{ position: "relative" }}>
                  <Image src="/assets/card1.png" className="imgcard"></Image>
                  <div
                    style={{
                      backgroundColor: "#fff",
                      // position: "absolute",
                      // top: "90%",
                      width: "90%",
                      minHeightn: "220px",
                      display: "flex",
                      flexDirection: "column",
                      padding: "15px 25px",
                    }}
                  >
                    <p>19 de Abril</p>
                    <p style={{ fontWeight: "bold" }}>Lobito de mar LA FINCA</p>
                    <p>A partir de las 19:00 horas.</p>
                    <button
                      onClick={() => {
                        setIdFrame("1");
                        setIsOpen(true);
                      }}
                    >
                      Reserva Ahora
                    </button>
                  </div>
                </div>
              </div>
            </Col>{" "}
            <Col xs={10} md={5}>
              <div className="cardronq">
                <div style={{ position: "relative" }}>
                  <Image src="/assets/card2.png" className="imgcard"></Image>
                  <div
                    style={{
                      backgroundColor: "#fff",
                      // position: "absolute",
                      // top: "90%",
                      width: "90%",
                      minHeightn: "220px",
                      display: "flex",
                      flexDirection: "column",
                      padding: "15px 25px",
                    }}
                  >
                    <p>9 de Mayo</p>
                    <p style={{ fontWeight: "bold" }}>BIBO TARIFA</p>
                    <p>A partir de las 18:30 horas.</p>
                    <button
                      onClick={() => {
                        setIdFrame("2");
                        setIsOpen(true);
                      }}
                    >
                      Reserva Ahora
                    </button>
                  </div>
                </div>
              </div>
            </Col>{" "}
          </Row>
          <Row
            style={{
              justifyContent: "flex-end",
              // minHeight: "225px",
              width: "100%",
              alignItems: "center",
              margin: "15px 0px  ",
            }}
          >
            <Col xs={12} md={5}>
              <Image
                src="/assets/img1.png"
                style={{ width: "100%", padding: "0px" }}
              ></Image>
            </Col>
          </Row>
          <Row
            className="inverse"
            style={{
              justifyContent: "flex-start",
              alignItems: "center",
              minHeight: "225px",
              width: "100%",
              margin: "15px 0px  ",
            }}
          >
            <Col xs={12} xl={6}>
              <Image
                src="/assets/img2.png"
                style={{ width: "100%", padding: "0px" }}
              ></Image>
            </Col>{" "}
            <Col xs={12} xl={1}></Col>
            <Col
              xs={12}
              xl={4}
              className="title"
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                textAlign: "left",
                paddingLeft: "15px",
              }}
            >
              El arte del ronqueo
            </Col>
          </Row>
          <Row
            style={{
              justifyContent: "flex-start",
              alignItems: "center",
              // minHeight: "225px",
              width: "100%",
              margin: "15px 0px  ",
            }}
          >
            <Col xs={12} md={6} className="title">
              El atún de almadraba
            </Col>
            <Col xs={12} md={6} style={{ padding: "15px" }}>
              <p style={{ maxWidth: "641px" }}>
                Técnica de pesca artesanal que se utiliza para capturar grandes
                cantidades de atún rojo en su migración anual por el Mar
                Mediterráneo. Consiste en colocar una red en forma de laberinto
                en el mar, donde los atunes quedan atrapados y son izados a
                bordo de los barcos.
              </p>
            </Col>
          </Row>{" "}
          <Row
            style={{
              justifyContent: "center",
              alignItems: "center",
              // minHeight: "225px",
              width: "100%",
              margin: "15px 0px  ",
            }}
          >
            <Col xs={12} md={12}>
              {" "}
              <Image
                src="/assets/img3.png"
                style={{ width: "100%", padding: "0px" }}
              ></Image>
            </Col>
          </Row>
          <Row
            style={{
              justifyContent: "space-between",
              alignItems: "center",
              // minHeight: "225px",
              width: "100%",
              margin: "15px 0px  ",
            }}
          >
            <Col xs={12} xl={4} className="title">
              Ediciones pasadas
            </Col>
            <Col xs={12} xl={8}>
              <Row style={{ margin: "0px" }}>
                <Col xs={12} md={6}>
                  {" "}
                  <Image
                    src="/assets/img6.png"
                    style={{
                      width: "100%",
                      // height: "400px",
                      padding: "15px",
                      objectFit: "fill",
                      aspectRatio: 1,
                    }}
                  ></Image>
                </Col>
                <Col xs={12} md={6}>
                  {" "}
                  <Image
                    src="/assets/img7.png"
                    style={{
                      width: "100%",

                      padding: "15px",
                      objectFit: "fill",
                      aspectRatio: 1,
                    }}
                  ></Image>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row
            style={{
              justifyContent: "space-between",
              alignItems: "center",
              minHeight: "225px",
              width: "100%",
              margin: "15px 0px  ",
            }}
          >
            <Col xs={12} md={6}>
              <div class="video-responsive">
                <iframe
                  src="https://www.youtube.com/embed/Vk-HkTUsqiM?si=ow2CUlQ82N5Tb4Em&amp;controls=0"
                  title="YouTube video player"
                  // frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              </div>
            </Col>
            <Col
              xs={12}
              md={6}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                // maxWidth: "356px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  maxWidth: "356px",
                }}
              >
                <p style={{ padding: "15px" }}>
                  Además no te pierdas las cartas especiales de atún que podrás
                  disfrutar en todos los Lobito de Mar.
                </p>
                <a
                  href="https://grupodanigarcia.com/lobito-de-mar/"
                  target="_blank"
                  rel="noreferrer"
                >
                  LOBITO DE MAR
                </a>
              </div>
            </Col>
          </Row>
          {/* <Patrocinadores></Patrocinadores> */}
        </div>
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            backgroundColor: "#0a2a2f",
            color: "#e3e1dc",
            minHeight: "80px",
            width: "100%",
            textAlign: "center",
            // margin: "15px 0px  ",
          }}
        >
          <div> &copy; Grupo Dani García {new Date().getFullYear()}</div>
        </div>
      </div>{" "}
      <Popup
        open={isOpen}
        closeOnDocumentClick
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <div style={{ padding: "15px" }}>
          <script
            type="text/javascript"
            src="https://www.covermanager.com/js/iframeResizer.min.js"
          ></script>
          <iframe
            id="myIframe"
            title="Reservas"
            src={idframe === "1" ? reservFinca : reservTarifa}
            frameborder="0"
            height="550"
            width="100%"
            onload="iFrameResize();"
          ></iframe>
        </div>
      </Popup>
    </>
  );
};

export default Home;
