// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAYHG0YhwY6Qr1H8az8iP8oGPTN2KbeFxs",
  authDomain: "web-atun.firebaseapp.com",
  projectId: "web-atun",
  storageBucket: "web-atun.appspot.com",
  messagingSenderId: "77945634984",
  appId: "1:77945634984:web:d3139faf1237fdc4d5029d"
};

// Initialize Firebase
const appFb = initializeApp(firebaseConfig);
//const analytics = getAnalytics(appFb);

export {appFb}